import { useEffect, useState } from "react";

interface ColorSelectorProps {
  initialData: string;
  onDataUpdate: (updatedData: string) => void;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  initialData,
  onDataUpdate,
}) => {
  const [color, setColor] = useState<string>(initialData);

  const handleColorChange = (newValue: string) => {
    setColor(newValue);
  };

  useEffect(() => {
    setColor(initialData);
  }, [initialData]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          size={10}
          value={color}
          onChange={(event) => handleColorChange(event.target.value)}
          style={{ marginRight: "0px" }} // Optional: Add some space between input and button
        />
        <button
          onClick={() => {
            onDataUpdate(color);
          }}
        >
          Update
        </button>
      </div>
    </>
  );
};

export default ColorSelector;
