export const exportChartAsPNG = (canvasElement: HTMLCanvasElement, svgElement: any, width: number, height: number, backgroundColor:string) => {

    console.log("export");
    const svgObj = document.getElementsByTagName("svg")[0];

    canvasElement.width = svgObj.clientWidth;
    canvasElement.height = svgObj.clientHeight;

    var ctx = canvasElement.getContext("2d");
    if (ctx) {
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvasElement.width,canvasElement.height);
    }

    var xmlSerializer = new XMLSerializer();
    var svgString = xmlSerializer.serializeToString(svgObj);

    var svg = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    var url = URL.createObjectURL(svg);

    let img = new Image();

    img.onerror = (error) => {
      console.log(error);
    };

    img.onload = function () {
      ctx?.drawImage(img, 0, 0);
      /*
      const a = document.createElement("a");
      a.download = "chart.png";
      a.href = canvasElement.toDataURL();
      a.click();
      */
    };

    img.src = url;
  };

 export const handleRecordCanvas = (canvas:  HTMLCanvasElement, duration:number) => {
    var video = document.querySelector("video");

    if (canvas) {
      var videoStream = canvas.captureStream(60);



      var mediaRecorder = new MediaRecorder(videoStream);


      var chunks: BlobPart[] | undefined = [];
      mediaRecorder.ondataavailable = function (e) {
        chunks?.push(e.data);
      };

      mediaRecorder.onstop = function (e) {
        var blob = new Blob(chunks, { type: "video/mp4" });
        chunks = [];
        var videoURL = URL.createObjectURL(blob);
        if (video) video.src = videoURL;
      };
      mediaRecorder.ondataavailable = function (e) {
        chunks?.push(e.data);
      };

      mediaRecorder.start();
      setTimeout(function () {
        mediaRecorder.stop();
      }, duration+1000);
    }
  };