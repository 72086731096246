import React, { useEffect, useState } from "react";
import "./App.css";
import Chart from "./components/LineChart";
import Editor from "./components/Editor";
import { Link } from "react-router-dom";
import { getChartsFromFirebase } from "./firebase";

function App() {
  const [charts, setCharts] = useState<string[]>([]);

  useEffect(() => {
    /*
    getChartsFromFirebase().then((newCharts) => {
      setCharts(newCharts);
    });
    */
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: "1",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <img src="logo_clr4.svg" style={{ height: "70px" }} />
        <h1>Vizuforge.com</h1>
        <p>
          <b>Create beautiful charts with animations - export as video!</b>
        </p>
        <Link to={"/editor/new"}>Open chart editor (BETA)</Link>
        <p style={{ maxWidth: "400px", padding: "1em" }}>
          Before using, you should know that this is only a beta version. The
          tool is still in heavy development. Use it at your own risk. &#128512;
        </p>
      </div>
      <div style={{ textAlign: "center", padding: "1em" }}>
        <Link to={"/privacy-policy"}>Privacy policy</Link>
      </div>
    </div>
    /*
    <div className="App">
      <ul>
        {charts.map((chartId) => (
          <li>
            <Link to={"/editor/" + chartId}>{chartId}</Link>
          </li>
        ))}
      </ul>
    </div>
    */
  );
}

export default App;
