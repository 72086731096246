import React, { useEffect, useRef, useState } from "react";
import LineChart from "./LineChart";
import {
  addChartToFirebase,
  getChartFromFirebase,
  updateChartInFirebase,
} from "../firebase";
import { render } from "react-dom";
import { Link, useParams } from "react-router-dom";
import DataEditor from "./DataEditor";
import ColorList from "./ColorList";
import BarChart from "./BarChart";
import ColorSelector from "./ColorSelector";
import ExpandableSection from "./ExpandableSection";
import { isContext } from "vm";
import { exportChartAsPNG } from "../utils/export";
import CanvasLineChart, { ExtraObjectInterface } from "./CanvasLineChart";

const Editor: React.FC = () => {
  const { vizuId } = useParams();
  const [code, setCode] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [chartId, setChartId] = useState<null | string>(null);

  const [pageWrapperWidth, setPageWrapperWidth] = useState(620);
  const [fixedChartWidth, setFixedChartWidth] = useState(600);
  const [fixedChartHeight, setFixedChartHeight] = useState(280);
  const [fixedMarginTop, setFixedMarginTop] = useState(20);
  const [fixedMarginBottom, setFixedMarginBottom] = useState(20);
  const [fixedMarginRight, setFixedMarginRight] = useState(20);

  const [showDataEditor, setShowDataEditor] = useState(false);
  const initialData = [["1"]];
  const [dataEditorData, setDataEditorData] = useState(initialData);

  const [fixedMarginLeft, setFixedMarginLeft] = useState(40);
  const [yAxisFontSize, setYAxisFontSize] = useState(12);
  const [xAxisFontSize, setXAxisFontSize] = useState(12);
  const [labelFontSize, setLabelFontSize] = useState(12);

  const [yAxisGap, setYAxisGap] = useState(8);
  const [xAxisGap, setXAxisGap] = useState(8);

  const [chartBorderWidth, setChartBorderWidth] = useState(1);
  const [chartBorderColor, setChartBorderColor] = useState("#cccccc");

  const [animationTime, setAnimationTime] = useState(5);

  const [extraObjects, setExtraObjects] = useState<ExtraObjectInterface[]>([]);
  const [editExtraObjectIndex, setEditExtraObjectIndex] = useState<
    number | null
  >(null);

  const [extraObjectText, setExtraObjectText] = useState("Example text");
  const [extraObjectTextFontSize, setExtraObjectTextFontSize] = useState(44);
  const [extraObjectTextFontColor, setExtraObjectTextFontColor] =
    useState("#77777766");

  const [data, setData] = useState<any[]>([
    { "2019": [{ Cats: 75 }, { Dogs: 54 }, { Horses: 14 }] },
    { "2020": [{ Cats: 72 }, { Dogs: 66 }, { Horses: 16 }] },
    { "2021": [{ Cats: 78 }, { Dogs: 65 }, { Horses: 18 }] },
    { "2022": [{ Cats: 75 }, { Dogs: 68 }, { Horses: 15 }] },
    { "2023": [{ Cats: 73 }, { Dogs: 71 }, { Horses: 11 }] },
    { "2024": [{ Cats: 79 }, { Dogs: 64 }, { Horses: 5 }] },
  ]);

  const [colors, setColors] = useState<string[]>([
    "#AD1010",
    "#FF9B25",
    "#02374D",
  ]);
  const [lineThickness, setLineThickness] = useState(2.5);
  const [backgroundLinesType, setBackgroundLinesType] = useState("dashed");
  const [chartType, setChartType] = useState("line");
  const [backgroundColor, setBackgroundColor] = useState("#FAF5EC");
  const [backgroundLineColor, setBackgroundLineColor] = useState("#A1998A");
  const [dataPointDotRadius, setDataPointDotRadius] = useState(6);

  const [chartLoaded, setChartLoaded] = useState(false);

  const [showScrollToVideoText, setShowScrollToVideoText] = useState(false);

  useEffect(() => {
    if (vizuId !== undefined && vizuId !== "new") {
      /*
      setChartId(vizuId);
      getChartFromFirebase(vizuId).then((chartData) => {
        console.log("chartData " + chartData);
        setData(chartData!.data);
        setColors(chartData!.colors);
        setNewColor(colors[0]);
        setLineThickness(chartData!.lineThickness);
        setBackgroundLinesType(chartData!.backgroundLinesType);
        setChartLoaded(true);
        convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(
          chartData!.data
        );
      });
      */
    } else {
      setChartLoaded(true);
      // Set data in data editor to be equal to initial data
      convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(data);
    }
  }, [vizuId]);

  const updateDataFromEditorCallback = (newData: any[]) => {
    setData(newData);
    convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor(newData);
  };

  const convertChartDataObjectToDataEditorArrayAndUpdateDataInDataEditor = (
    newData: any
  ) => {
    const dataEditorArray: string[][] = [[]];

    // Push first non existing upper left
    dataEditorArray[0].push("");

    for (let i = 0; i < newData.length; i++) {
      const dataElement = newData[i] as any;
      const dataKey = Object.keys(dataElement)[0];

      dataEditorArray.push([dataKey]);

      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, index: number) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key, indexSubKeys) => {
          const value = _element[_key];
          dataEditorArray[i + 1].push(value);
          if (!dataEditorArray[0].includes(_key)) dataEditorArray[0].push(_key);
        });
      });
    }
    setDataEditorData(dataEditorArray);
  };

  // Save chart template to localStorage
  const saveTemplateToLocal = (templateName: string) => {
    const chartData = {
      colors: colors,
      lineThickness: lineThickness,
      backgroundLinesType: backgroundLinesType,
      parentWidth: pageWrapperWidth,
      fixedChartWidth: fixedChartWidth,
      fixedChartHeight: fixedChartHeight,
      fixedMarginLeft: fixedMarginLeft,
      fixedMarginTop: fixedMarginTop,
      fixedMarginBottom: fixedMarginBottom,
      fixedMarginRight: fixedMarginRight,
      backgroundColor: backgroundColor,
      backgroundLineColor: backgroundLineColor,
      yAxisFontSize: yAxisFontSize,
      xAxisFontSize: xAxisFontSize,
      labelFontSize: labelFontSize,
      dataPointDotRadius: dataPointDotRadius,
      chartBorderWidth: chartBorderWidth,
      chartBorderColor: chartBorderColor,
      xAxisGap: xAxisGap,
      yAxisGap: yAxisGap,
    };

    const localTemplateData = localStorage.getItem("chartTemplates");
    if (localTemplateData) {
      const templates = JSON.parse(localTemplateData) || {};
      templates[templateName] = chartData;
      localStorage.setItem("chartTemplates", JSON.stringify(templates));
      console.log("saved");
    } else {
      localStorage.setItem(
        "chartTemplates",
        JSON.stringify({ [templateName]: chartData })
      );
    }
  };

  // Get chart template from localStorage
  const getTemplateFromLocal = (templateName: string) => {
    const localTemplateData = localStorage.getItem("chartTemplates");
    if (localTemplateData) {
      const templates = JSON.parse(localTemplateData) || {};
      const template = templates[templateName];

      if (template["labelFontSize"]) {
        setLabelFontSize(template["labelFontSize"]);
      }
      if (template["colors"]) {
        setColors(template["colors"]);
      }
      if (template["lineThickness"]) {
        setLineThickness(template["lineThickness"]);
      }
      if (template["backgroundLinesType"]) {
        setBackgroundLinesType(template["backgroundLinesType"]);
      }
      if (template["parentWidth"]) {
        setPageWrapperWidth(template["parentWidth"]);
      }
      if (template["fixedChartWidth"]) {
        setFixedChartWidth(template["fixedChartWidth"]);
      }
      if (template["fixedChartHeight"]) {
        setFixedChartHeight(template["fixedChartHeight"]);
      }
      if (template["fixedMarginLeft"]) {
        setFixedMarginLeft(template["fixedMarginLeft"]);
      }
      if (template["fixedMarginTop"]) {
        setFixedMarginTop(template["fixedMarginTop"]);
      }
      if (template["fixedMarginBottom"]) {
        setFixedMarginBottom(template["fixedMarginBottom"]);
      }
      if (template["fixedMarginRight"]) {
        setFixedMarginRight(template["fixedMarginRight"]);
      }
      if (template["backgroundColor"]) {
        setBackgroundColor(template["backgroundColor"]);
      }
      if (template["backgroundLineColor"]) {
        setBackgroundLineColor(template["backgroundLineColor"]);
      }
      if (template["yAxisFontSize"]) {
        setYAxisFontSize(template["yAxisFontSize"]);
      }
      if (template["xAxisFontSize"]) {
        setXAxisFontSize(template["xAxisFontSize"]);
      }
      if (template["dataPointDotRadius"] >= -1) {
        setDataPointDotRadius(template["dataPointDotRadius"]);
        console.log("dotradius" + template["dataPointDotRadius"]);
      }
      if (template["chartBorderWidth"]) {
        setChartBorderWidth(template["chartBorderWidth"]);
      }
      if (template["chartBorderColor"]) {
        setChartBorderColor(template["chartBorderColor"]);
      }
      if (template["yAxisGap"]) {
        setYAxisGap(template["yAxisGap"]);
      }
      if (template["xAxisGap"]) {
        setXAxisGap(template["xAxisGap"]);
      }
    }
  };

  const handleGetCode = () => {
    const element = document.getElementById("target");
    if (element) {
      setCode(element.innerHTML);
    }
  };

  const handleCopyToClipboard = () => {
    if (code) {
      navigator.clipboard.writeText(code).then(() => {
        setCopied(true); // Set copied to true to show feedback
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      });
    }
  };

  const handleAddExtraObject = (extraObject: ExtraObjectInterface) => {
    setExtraObjects([...extraObjects, extraObject]);
  };

  const handleEditExtraObject = (extraObjectIndex: number) => {
    const extraObject = extraObjects[extraObjectIndex];
    setExtraObjectText(extraObject.value.textValue);
    setExtraObjectTextFontSize(extraObject.value.fontSize);
    setEditExtraObjectIndex(extraObjectIndex);
    console.log("handle edit " + extraObjectIndex);
  };

  const handleRecordFinished = () => {
    setShowScrollToVideoText(true);

    // Show scroll down message for 10 seconds
    setTimeout(function () {
      setShowScrollToVideoText(false);
    }, 10000);
  };

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div
        className="editorLeftSidebar"
        style={{
          display: "flex",
          maxWidth: "4vw",
          minWidth: "80px",
          flexGrow: "0",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            alignItems: "stretch",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <img
              src="logo_white.svg"
              style={{
                height: "25px",
                paddingTop: "15px",
                paddingBottom: "10px",
                opacity: "1",
              }}
            />
          </div>

          <button
            className={showDataEditor ? "active" : "inactive"}
            onClick={() => setShowDataEditor(true)}
            style={{}}
          >
            Edit data
          </button>

          <button
            className={!showDataEditor ? "active" : "inactive"}
            style={{}}
            onClick={() => setShowDataEditor(false)}
          >
            Edit chart
          </button>
        </div>
      </div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexGrow: "4",
          overflowX: "hidden",
          flexDirection: "column",
        }}
        id="outerWrapper"
      >
        {showDataEditor ? (
          <div
            style={{
              width: "100vw",
              padding: "10px",
              height: "100vh",
            }}
          >
            <DataEditor
              initialData={dataEditorData}
              onDataUpdate={updateDataFromEditorCallback}
            />
          </div>
        ) : (
          <div
            className="chartAndRightSidebarWrapper"
            style={{
              flexGrow: "4",
              display: "flex",
              height: "100vh",
              flexDirection: "row",
            }}
          >
            <div
              className="extraElementEditorAndChartWrapper"
              style={{
                flexGrow: "1",
                display: "flex",
                height: "100vh",
                flexDirection: "column",
                overflowY: "hidden",
              }}
            >
              <div
                className="outerScrollElement"
                style={{
                  display: "flex",
                  maxHeight: "100vh",
                  flexDirection: "row",
                  scrollbarColor: "#242429 #121214",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                }}
              >
                <div
                  className="chartWrapperBox"
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      minWidth: "0",
                      alignItems: "center",
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: "#131416",
                    }}
                  >
                    {chartLoaded && (
                      <div
                        style={{
                          height: "100vh",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {chartType === "line" && (
                          <CanvasLineChart
                            data={data}
                            colors={colors}
                            lineThickness={lineThickness}
                            backgroundLinesType={backgroundLinesType}
                            fixedChartWidth={fixedChartWidth}
                            fixedChartHeight={fixedChartHeight}
                            fixedMarginLeft={fixedMarginLeft}
                            fixedMarginTop={fixedMarginTop}
                            fixedMarginBottom={fixedMarginBottom}
                            fixedMarginRight={fixedMarginRight}
                            backgroundColor={backgroundColor}
                            backgroundLineColor={backgroundLineColor}
                            yAxisFontSize={yAxisFontSize}
                            xAxisFontSize={xAxisFontSize}
                            labelFontSize={labelFontSize}
                            dataPointsDotRadius={dataPointDotRadius}
                            chartBorderWidth={chartBorderWidth}
                            chartBorderColor={chartBorderColor}
                            yAxisGap={yAxisGap}
                            xAxisGap={xAxisGap}
                            animationTimeInSeconds={animationTime}
                            onExtraObjectClick={handleEditExtraObject}
                            onAddExtraObject={handleAddExtraObject}
                            extraObjects={extraObjects}
                            currentEditExtraObjectIndex={editExtraObjectIndex}
                            onRecordFinished={handleRecordFinished}
                          />
                        )}

                        {chartType === "bar" && (
                          <BarChart
                            data={data}
                            colors={colors}
                            lineThickness={lineThickness}
                            backgroundLinesType={backgroundLinesType}
                            parentWidth={pageWrapperWidth}
                            fixedChartWidth={fixedChartWidth}
                            fixedChartHeight={fixedChartHeight}
                            fixedMarginLeft={fixedMarginLeft}
                            fixedMarginTop={fixedMarginTop}
                            fixedMarginBottom={fixedMarginBottom}
                            fixedMarginRight={fixedMarginRight}
                            backgroundColor={backgroundColor}
                            backgroundLineColor={backgroundLineColor}
                            yAxisFontSize={yAxisFontSize}
                            xAxisFontSize={xAxisFontSize}
                            labelFontSize={labelFontSize}
                            chartBorderWidth={chartBorderWidth}
                            chartBorderColor={chartBorderColor}
                            yAxisGap={yAxisGap}
                            xAxisGap={xAxisGap}
                          />
                        )}
                        {showScrollToVideoText && (
                          <div className="scrollDownToVideo">
                            Scroll down for video
                          </div>
                        )}
                      </div>
                    )}
                    <>
                      <div style={{ margin: "10px", border: "1px solid #000" }}>
                        <video controls></video>
                      </div>
                    </>
                    <div style={{ textAlign: "center", padding: "0.1em" }}>
                      <Link to={"/privacy-policy"}>Privacy policy</Link>
                    </div>
                  </div>
                </div>
              </div>

              {editExtraObjectIndex != null && (
                <div
                  className="extraObjectEditor"
                  style={{
                    backgroundColor: "#2e2e33",
                    color: "#fff",
                    borderTop: "1px solid #3e3e45",
                    padding: "1em",
                    flexGrow: "4",
                  }}
                >
                  Text value:
                  <input
                    type="text"
                    value={extraObjectText}
                    onChange={(event) => {
                      setExtraObjectText(event.target.value);

                      const newExtraObjects = extraObjects;
                      newExtraObjects[editExtraObjectIndex].value = {
                        textValue: event.target.value,
                        fontColor:
                          newExtraObjects[editExtraObjectIndex].value.fontColor,
                        fontSize:
                          newExtraObjects[editExtraObjectIndex].value.fontSize,
                      };
                      setExtraObjects(newExtraObjects);
                    }}
                  />
                  Font color:
                  <input
                    type="text"
                    value={extraObjectTextFontColor}
                    onChange={(event) => {
                      setExtraObjectTextFontColor(event.target.value);

                      const newExtraObjects = extraObjects;
                      newExtraObjects[editExtraObjectIndex].value = {
                        textValue:
                          newExtraObjects[editExtraObjectIndex].value.textValue,
                        fontColor: event.target.value,
                        fontSize:
                          newExtraObjects[editExtraObjectIndex].value.fontSize,
                      };
                      setExtraObjects(newExtraObjects);
                    }}
                  />
                  <input
                    type="range"
                    step="1"
                    min="4"
                    max="100"
                    value={extraObjectTextFontSize}
                    onChange={(event) => {
                      setExtraObjectTextFontSize(event.target.valueAsNumber);

                      const newExtraObjects = extraObjects;
                      newExtraObjects[editExtraObjectIndex].value = {
                        textValue:
                          newExtraObjects[editExtraObjectIndex].value.textValue,
                        fontColor:
                          newExtraObjects[editExtraObjectIndex].value.fontColor,
                        fontSize: event.target.valueAsNumber,
                      };
                      setExtraObjects(newExtraObjects);
                    }}
                  />
                  {extraObjectTextFontSize} px
                  <button
                    onClick={() => {
                      if (editExtraObjectIndex != null) {
                        setExtraObjects([
                          ...extraObjects.slice(0, editExtraObjectIndex),
                          ...extraObjects.slice(editExtraObjectIndex + 1),
                        ]);

                        setEditExtraObjectIndex(null);
                      }
                    }}
                  >
                    Delete object
                  </button>
                  <button
                    onClick={() => {
                      setEditExtraObjectIndex(null);
                    }}
                  >
                    Close editing
                  </button>
                </div>
              )}
            </div>

            <div
              className="editorSidebar"
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "200px",
                padding: "5px",
                paddingLeft: "10px",
              }}
            >
              <h3>Template:</h3>
              <button
                onClick={() => {
                  saveTemplateToLocal("my-template");
                }}
              >
                Save template
              </button>
              <button
                onClick={() => {
                  getTemplateFromLocal("my-template");
                }}
              >
                Get template
              </button>
              <div
                style={{
                  maxWidth: "190px",
                  fontSize: "0.85em",
                  paddingTop: "0.9em",
                }}
              >
                <i>
                  Templates are saved to your locale browser storage. Deleting
                  browser data, will also delete the templates.
                </i>
              </div>
              <h3>Chart</h3>
              <h4>Chart type</h4>
              <div>
                <select
                  name="selectChartType"
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                >
                  <option value="line">Line</option>
                </select>
              </div>
              <ExpandableSection title="Categories">
                <h5>Colors</h5>
                <ColorList
                  initialData={colors}
                  onDataUpdate={(newColors) => {
                    setColors(newColors);
                  }}
                />
              </ExpandableSection>
              <ExpandableSection title="Background and grid">
                {chartBorderWidth > 99999 && (
                  <>
                    <h5>Chart border color</h5>
                    <ColorSelector
                      initialData={chartBorderColor}
                      onDataUpdate={(newColor) => {
                        setChartBorderColor(newColor);
                      }}
                    ></ColorSelector>
                  </>
                )}
                <h5>Background color</h5>
                <ColorSelector
                  initialData={backgroundColor}
                  onDataUpdate={(newColor) => {
                    setBackgroundColor(newColor);
                  }}
                ></ColorSelector>
                <h5>Grid type</h5>
                <select
                  name="selectBackgroundLines"
                  value={backgroundLinesType}
                  onChange={(e) => setBackgroundLinesType(e.target.value)}
                >
                  <option value="solid">Solid</option>
                  <option value="dashed">Dashed</option>
                  <option value="none">None</option>
                </select>
                {backgroundLinesType != "none" && (
                  <>
                    <h5>Grid color</h5>
                    <ColorSelector
                      initialData={backgroundLineColor}
                      onDataUpdate={(newColor) => {
                        setBackgroundLineColor(newColor);
                      }}
                    ></ColorSelector>
                  </>
                )}
              </ExpandableSection>
              <ExpandableSection title="Size and margins">
                <h5>Chart width</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="2000"
                  value={fixedChartWidth}
                  onChange={(event) => {
                    setFixedChartWidth(event.target.valueAsNumber);
                  }}
                />
                <h5>Chart height</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="2000"
                  value={fixedChartHeight}
                  onChange={(event) => {
                    setFixedChartHeight(event.target.valueAsNumber);
                  }}
                />
                <h5>Margin left</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="150"
                  value={fixedMarginLeft}
                  onChange={(event) => {
                    setFixedMarginLeft(event.target.valueAsNumber);
                  }}
                />
                <h5>Margin top</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="150"
                  value={fixedMarginTop}
                  onChange={(event) => {
                    setFixedMarginTop(event.target.valueAsNumber);
                  }}
                />
                <h5>Margin bottom</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="150"
                  value={fixedMarginBottom}
                  onChange={(event) => {
                    setFixedMarginBottom(event.target.valueAsNumber);
                  }}
                />
                <h5>Margin right</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="150"
                  value={fixedMarginRight}
                  onChange={(event) => {
                    setFixedMarginRight(event.target.valueAsNumber);
                  }}
                />
              </ExpandableSection>
              <ExpandableSection title="Axis">
                <h5>Y-axis font size</h5>
                <input
                  type="range"
                  step="1"
                  min="4"
                  max="40"
                  value={yAxisFontSize}
                  onChange={(event) => {
                    setYAxisFontSize(event.target.valueAsNumber);
                  }}
                />
                <h5>Y-axis gap</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="20"
                  value={yAxisGap}
                  onChange={(event) => {
                    setYAxisGap(event.target.valueAsNumber);
                  }}
                />
                <h5>X-axis font size</h5>
                <input
                  type="range"
                  step="1"
                  min="4"
                  max="40"
                  value={xAxisFontSize}
                  onChange={(event) => {
                    setXAxisFontSize(event.target.valueAsNumber);
                  }}
                />
                <h5>X-axis gap</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="20"
                  value={xAxisGap}
                  onChange={(event) => {
                    setXAxisGap(event.target.valueAsNumber);
                  }}
                />
              </ExpandableSection>
              <ExpandableSection title="Labels">
                <h5>Font size</h5>
                <input
                  type="range"
                  step="1"
                  min="4"
                  max="40"
                  value={labelFontSize}
                  onChange={(event) => {
                    setLabelFontSize(event.target.valueAsNumber);
                  }}
                />
              </ExpandableSection>
              {chartType === "line" && (
                <ExpandableSection title="Line">
                  <h5>Line thickness</h5>
                  <input
                    type="range"
                    step="0.1"
                    min="0.1"
                    max="10"
                    value={lineThickness}
                    onChange={(event) => {
                      setLineThickness(event.target.valueAsNumber);
                    }}
                  />

                  <h5>Data point dot radius</h5>
                  <input
                    type="range"
                    step="0.1"
                    min="0"
                    max="10"
                    value={dataPointDotRadius}
                    onChange={(event) => {
                      setDataPointDotRadius(event.target.valueAsNumber);
                    }}
                  />
                </ExpandableSection>
              )}
              {false && (
                <button
                  onClick={async () => {
                    if (chartId !== null && chartId !== "new") {
                      updateChartInFirebase(
                        chartId,
                        data,
                        colors,
                        lineThickness,
                        backgroundLinesType
                      );
                    } else {
                      const newChartId = await addChartToFirebase(
                        data,
                        colors,
                        lineThickness,
                        backgroundLinesType
                      );
                      setChartId(newChartId);
                    }
                  }}
                >
                  Store chart
                </button>
              )}
              <h3>Animation</h3>
              <ExpandableSection title="Duration">
                <h5>Duration - {animationTime} seconds</h5>
                <input
                  type="range"
                  step="1"
                  min="1"
                  max="60"
                  value={animationTime}
                  onChange={(event) => {
                    setAnimationTime(event.target.valueAsNumber);
                  }}
                />
              </ExpandableSection>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editor;
