import React, { useState } from "react";
import Papa from "papaparse";

interface DataEditorProps {
  initialData: string[][];
  onDataUpdate: (updatedData: any[]) => void;
}

interface ChartData {
  [key: string]: number;
}

const DataEditor: React.FC<DataEditorProps> = ({
  initialData,
  onDataUpdate,
}) => {
  const [data, setData] = useState<string[][]>(initialData);

  const handleCellEdit = (
    rowIndex: number,
    colIndex: number,
    newValue: string
  ) => {
    const updatedData = [...data];
    updatedData[rowIndex][colIndex] = newValue;
    setData(updatedData);
  };

  const addRow = () => {
    const updatedData = [...data];
    const newRow = Array(data[0].length).fill(""); // Initialize with empty strings
    updatedData.push(newRow);
    setData(updatedData);
  };

  const addColumn = () => {
    const updatedData = [...data];
    updatedData.forEach((row) => {
      row.push(""); // Initialize with empty string for new column
    });
    setData(updatedData);
  };

  const convertDataToChartDataObject = () => {
    const returnArray: { [key: string]: ChartData[] }[] = [];
    data.map((row, rowIndex) => {
      if (rowIndex > 0) {
        const rowData = [] as any[];
        const cellsInRow = data[rowIndex];
        cellsInRow.map((cell, cellIndex) => {
          if (cellIndex > 0) {
            const cellObject = { [data[0][cellIndex]]: parseFloat(cell) };
            if (cell !== ".") rowData.push(cellObject);
          }
        });

        returnArray.push({ [data[rowIndex][0]]: rowData });
      }
    });

    onDataUpdate(returnArray); // Call the callback with updated data
  };

  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          // Convert the CSV results to the desired format
          const csvData = results.data as string[][];
          setData(csvData);
          convertDataToChartDataObject(); // Optional: immediately convert to chart data
        },
        header: false, // Change this to true if your CSV has headers
      });
    }
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleCSVUpload} />

      <table>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => (
                <td key={colIndex}>
                  <input
                    type="text"
                    value={cell}
                    onChange={(e) =>
                      handleCellEdit(rowIndex, colIndex, e.target.value)
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addRow}>Add row</button>
      <button onClick={addColumn}>Add column</button>

      <button onClick={convertDataToChartDataObject}>
        Save and update chart
      </button>
    </div>
  );
};

export default DataEditor;
