import React, { useEffect, useRef, useState } from "react";
import { handleRecordCanvas } from "../utils/export";

export interface ExtraObjectInterface {
  type: "text";
  value: { textValue: string; fontSize: number; fontColor: string };
  object: any;
  hover: boolean;
  moving: boolean;
  movingOffsetPosition: { x: number; y: number };
  position: { x: number; y: number };
}

interface ChartProps {
  data: number[];
  colors: string[];
  lineThickness: number;
  backgroundLinesType: string;
  parentWidth?: number | undefined;
  fixedChartWidth: number;
  fixedChartHeight: number;
  fixedMarginLeft: number;
  fixedMarginTop: number;
  fixedMarginBottom: number;
  fixedMarginRight: number;
  backgroundColor: string;
  backgroundLineColor: string;
  yAxisFontSize: number;
  xAxisFontSize: number;
  labelFontSize: number;
  dataPointsDotRadius: number;
  chartBorderWidth: number;
  chartBorderColor: string;
  yAxisGap: number;
  xAxisGap: number;
  animationTimeInSeconds: number;
  onAddExtraObject: (extraObject: ExtraObjectInterface) => void;
  onExtraObjectClick: (extraObjectIndex: number) => void;
  extraObjects: ExtraObjectInterface[];
  currentEditExtraObjectIndex: number | null;
  onRecordFinished: () => void;
}

const CanvasLineChart: React.FC<ChartProps> = (props) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null); // Ref for the canvas context
  //const extraObjects = useRef<ExtraObjectInterface[]>([]);

  const width = props.fixedChartWidth;
  const height = props.fixedChartHeight;
  const labelWidthRef = useRef(0);
  const yAxisWidthRef = useRef(0);
  const xAxisTextHeightRef = useRef(0);

  let maxDataRef = useRef(0);
  let pathsRef = useRef<Record<string, { x: number; y: number }[]> | null>(
    null
  );
  const animationFrameIdRef = useRef<number>();

  const [currentElapsed, setCurrentElapsed] = useState(0);

  let mousePosition = useRef({ x: 0, y: 0 });

  // Add a click event handler to the canvas
  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect) {
      props.extraObjects.map((extraObject, index) => {
        if (extraObject.hover) props.onExtraObjectClick(index); // Trigger the callback
      });
    }
  };

  const handleCanvasMouseDown = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect) {
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      props.extraObjects.map((extraObject) => {
        if (extraObject.hover) {
          console.log("clicked object");
          extraObject.moving = true;
          extraObject.movingOffsetPosition.x = extraObject.position.x - x;
          extraObject.movingOffsetPosition.y = extraObject.position.y - y;
          console.log(extraObject.movingOffsetPosition);
        }
      });
    }
  };

  const handleCanvasMouseUp = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect) {
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      props.extraObjects.map((extraObject) => {
        if (extraObject.hover) {
          extraObject.moving = false;
        }
      });
    }
  };

  // Add a mouse move  handler to the canvas
  const handleCanvasMouseMove = (
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    if (!isAnimating) {
      const rect = canvasRef.current?.getBoundingClientRect();
      if (rect) {
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        mousePosition.current.x = x;
        mousePosition.current.y = y;

        props.extraObjects.map((extraObject) => {
          if (extraObject.moving) {
            extraObject.position.x = x + extraObject.movingOffsetPosition.x;
            extraObject.position.y = y + extraObject.movingOffsetPosition.y;
          }
        });

        redraw();
      }
    }
  };

  const redraw = () => {
    const ctx = ctxRef.current;
    if (ctx && pathsRef.current) {
      resetAndDrawBackground(ctx);
      drawYAxis(ctx);
      drawXAxis(ctx);

      drawLines(ctx, pathsRef.current, null);
      drawExtraObjects(ctx);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    labelWidthRef.current = 0;
    yAxisWidthRef.current = 0;
    xAxisTextHeightRef.current = 0;

    //extraObjects.current = props.initialExtraObjects;

    if (canvas) {
      ctxRef.current = canvas.getContext("2d"); // Set context to ctxRef.current
      if (ctxRef.current) {
        calculateMaxData(ctxRef.current);

        pathsRef.current = mapDataToPath(props.data);

        // Start animation
        if (animationFrameIdRef.current)
          cancelAnimationFrame(animationFrameIdRef.current);
        animateLines(ctxRef.current, pathsRef.current, currentElapsed);
      }
    }

    return () => {
      if (animationFrameIdRef.current)
        cancelAnimationFrame(animationFrameIdRef.current);
    };
  }, [props]);

  const calculateMaxData = (ctx: CanvasRenderingContext2D) => {
    // Calculate label width
    const fontString = "bold " + props.labelFontSize + "px sans-serif";
    ctx.font = fontString;
    for (let i = 0; i < props.data.length; i++) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key) => {
          // Calculate text length at first run and update labelWidth
          let textWidth = ctx.measureText(_key).width + 10;
          if (textWidth > labelWidthRef.current) {
            labelWidthRef.current = textWidth;
          }
        });
      });
    }

    // Calculate maxData
    let maxData = -9999999;

    for (let i = 0; i < props.data.length; i++) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any, index: number) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key, index) => {
          const value = _element[_key];
          if (value > maxData) {
            maxData = value;
          }
        });
      });
    }

    // Add some more than max for spacing
    maxData = maxData * 1.17;
    maxDataRef.current = maxData;

    // Calculate y axis width
    ctx.font = props.yAxisFontSize + "px sans-serif";
    const gapY = maxDataRef.current / 10;
    for (let i = 0; i < 10; i++) {
      const yValue = i * gapY;
      let textWidth = ctx.measureText(Math.round(yValue) + "").width + 10;
      if (textWidth > yAxisWidthRef.current) {
        yAxisWidthRef.current = textWidth;
      }
    }

    // Calculate x axis height
    // Draw x axis
    ctx.font = props.xAxisFontSize + "px sans-serif";
    const dataLength = props.data.length;
    let xGap = 1;
    if (dataLength) {
      xGap = parseInt(dataLength / 10 + "");
      if (xGap < 1) {
        xGap = 1;
      }
    }
    for (let i = 0; i < dataLength; i += xGap) {
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const textMeasures = ctx.measureText(dataKey + "");
      let textHeight =
        textMeasures.actualBoundingBoxAscent +
        textMeasures.actualBoundingBoxDescent;

      if (textHeight > xAxisTextHeightRef.current) {
        xAxisTextHeightRef.current = textHeight;
      }
    }
  };

  const mapDataToPath = (
    data: number[]
  ): Record<string, { x: number; y: number }[]> => {
    let pathDatas: Record<string, { x: number; y: number }[]> = {};

    for (let i = 0; i < data.length; i++) {
      const dataElement = data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      const dataSubElement = dataElement[dataKey];

      dataSubElement.forEach((_element: any) => {
        const dataSubElementKeys = Object.keys(_element);

        dataSubElementKeys.forEach((_key) => {
          if (!pathDatas[_key]) pathDatas[_key] = [];
          const value = _element[_key];

          pathDatas[_key].push({
            x:
              (i / (data.length - 1)) *
                (width -
                  yAxisWidthRef.current -
                  props.yAxisGap -
                  props.fixedMarginLeft -
                  props.fixedMarginRight -
                  labelWidthRef.current) +
              props.fixedMarginLeft +
              props.yAxisGap +
              yAxisWidthRef.current,
            y:
              props.fixedMarginTop +
              (1 - value / maxDataRef.current) *
                (height -
                  15 -
                  props.fixedMarginTop -
                  props.fixedMarginBottom -
                  props.xAxisGap -
                  xAxisTextHeightRef.current),
          });
        });
      });
    }

    return pathDatas;
  };

  const interpolate = (start: number, end: number, factor: number) =>
    start + (end - start) * factor;

  const drawExtraObjects = (ctx: CanvasRenderingContext2D) => {
    props.extraObjects.map((extraObject, index) => {
      // Text value, fontColor and fontSize
      const textValue = extraObject.value.textValue;
      const fontSize = extraObject.value.fontSize;
      const fontColor = extraObject.value.fontColor;

      // X and y pos
      const x = extraObject.position.x;
      const y = extraObject.position.y;

      // Styling of text
      ctx.font = "bold " + fontSize + "px Arial";
      ctx.fillStyle = fontColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "hanging";

      // Measure width and height of text
      const textMeasures = ctx.measureText(textValue);
      const textWidth = textMeasures.width;

      const textHeight =
        textMeasures.actualBoundingBoxAscent +
        textMeasures.actualBoundingBoxDescent;

      // Draw box around text
      const box = new Path2D();
      box.rect(x - textWidth * 0.5, y, textWidth, textHeight);

      // Listener for point in stroke of box
      if (mousePosition) {
        const isPointInStroke = ctx.isPointInPath(
          box,
          mousePosition.current.x,
          mousePosition.current.y
        );

        if (index == props.currentEditExtraObjectIndex) {
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.setLineDash([4, 2]);
          ctx.stroke(box);
        }

        if (isPointInStroke) {
          ctx.lineWidth = 1;
          extraObject.hover = true;
          ctx.strokeStyle = "red";
          ctx.setLineDash([]);

          ctx.stroke(box);
        } else {
          extraObject.hover = false;
        }
      }

      // Draw the text
      ctx.fillText(`${textValue}`, x, y);
    });
  };

  const resetAndDrawBackground = (ctx: CanvasRenderingContext2D) => {
    //@ts-ignore
    ctx.reset();
    ctx.fillStyle = props.backgroundColor ? props.backgroundColor : "#fff";
    ctx.fillRect(0, 0, width, height); // Clear canvas before each frame
  };

  const drawLines = (
    ctx: CanvasRenderingContext2D,
    paths: Record<string, { x: number; y: number }[]>,
    currentProgress: number | null
  ) => {
    ctx.lineWidth = props.lineThickness;
    Object.keys(paths).forEach((key, keyIndex) => {
      // Set color based on keyIndex
      ctx.strokeStyle = props.colors[keyIndex];
      ctx.fillStyle = props.colors[keyIndex];

      // Label text settings
      ctx.textBaseline = "middle";
      const fontString = "bold " + props.labelFontSize + "px sans-serif";
      ctx.font = fontString;
      ctx.textAlign = "left";

      // Draw line
      const path = paths[key];
      ctx.beginPath();
      ctx.moveTo(path[0].x, path[0].y);
      // Draw circles at start
      if (props.dataPointsDotRadius > 0) {
        ctx.lineWidth = 0;
        ctx.arc(
          path[0].x,
          path[0].y,
          props.dataPointsDotRadius,
          0,
          2 * Math.PI
        );
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(path[0].x, path[0].y);
      }
      // Draw line segment by segment
      for (let i = 1; i < path.length; i++) {
        // Calculate interpolated point between path[i - 1] and path[i]
        const start = path[i - 1];
        const end = path[i];
        if (
          currentProgress &&
          currentProgress >= i - 1 &&
          currentProgress < i
        ) {
          // Interpolate position between the current segment start and end
          const factor = currentProgress - (i - 1);
          const interpolatedX = interpolate(start.x, end.x, factor);
          const interpolatedY = interpolate(start.y, end.y, factor);
          // Draw up to the interpolated point
          ctx.lineTo(interpolatedX, interpolatedY);
          ctx.stroke();
          // Draw legend text
          ctx.fillText(key, interpolatedX + 10, interpolatedY);
          break;
        } else if (path.length - 1 == i) {
          // Draw legend text
          ctx.fillText(key, end.x + 10, end.y);
        }
        // Draw fully completed segments
        ctx.lineTo(end.x, end.y);
        ctx.stroke();
        // Draw circle at point
        if (props.dataPointsDotRadius > 0) {
          ctx.beginPath();
          ctx.arc(end.x, end.y, props.dataPointsDotRadius, 0, 2 * Math.PI);
          ctx.fill();
          ctx.beginPath();
          ctx.moveTo(end.x, end.y);
        } else {
          ctx.beginPath();
          ctx.moveTo(end.x, end.y);
        }
      }
    });
  };

  const drawYAxis = (ctx: CanvasRenderingContext2D) => {
    ctx.strokeStyle = "#000";
    ctx.fillStyle = "#000";
    ctx.textBaseline = "middle";
    ctx.font = props.yAxisFontSize + "px sans-serif";
    ctx.textAlign = "right";
    ctx.lineWidth = 1;
    const gap = maxDataRef.current / 10;
    for (let i = 0; i < 10; i++) {
      const yValue = i * gap;
      const yPos =
        props.fixedMarginTop +
        (1 - yValue / maxDataRef.current) *
          (height -
            15 -
            props.fixedMarginTop -
            props.fixedMarginBottom -
            props.xAxisGap -
            xAxisTextHeightRef.current);
      ctx.fillText(
        Math.round(yValue) + "",
        props.fixedMarginLeft + yAxisWidthRef.current - 10,
        yPos
      );
      ctx.beginPath();
      ctx.strokeStyle = "#000000ff";
      ctx.moveTo(
        props.fixedMarginLeft + props.yAxisGap + yAxisWidthRef.current - 7,
        yPos
      );
      ctx.lineTo(
        props.fixedMarginLeft + props.yAxisGap + yAxisWidthRef.current - 0,
        yPos
      );
      ctx.stroke();
      if (props.backgroundLinesType != "none") {
        ctx.strokeStyle = props.backgroundLineColor;
        if (props.backgroundLinesType == "dashed") {
          ctx.setLineDash([10, 10]);
        } else {
          ctx.setLineDash([]);
        }
        ctx.beginPath();
        ctx.moveTo(
          props.fixedMarginLeft + props.yAxisGap + yAxisWidthRef.current,
          yPos
        );
        ctx.lineTo(
          props.fixedChartWidth -
            props.fixedMarginRight -
            labelWidthRef.current,
          yPos
        );
      }
      if (i == 0) {
        ctx.strokeStyle = "#000";
        ctx.setLineDash([]);
        ctx.lineTo(
          props.fixedChartWidth -
            props.fixedMarginRight -
            labelWidthRef.current,
          yPos
        );
      }
      ctx.stroke();
      ctx.setLineDash([]);
    }
  };

  const drawXAxis = (ctx: CanvasRenderingContext2D) => {
    ctx.font = props.xAxisFontSize + "px sans-serif";
    const dataLength = props.data.length;
    let xGap = 1;
    if (dataLength) {
      xGap = parseInt(dataLength / 10 + "");
      if (xGap < 1) {
        xGap = 1;
      }
    }
    for (let i = 0; i < dataLength; i += xGap) {
      const x =
        (i / (dataLength - 1)) *
          (width -
            props.fixedMarginLeft -
            props.fixedMarginRight -
            props.yAxisGap -
            yAxisWidthRef.current -
            labelWidthRef.current) +
        yAxisWidthRef.current +
        props.yAxisGap +
        props.fixedMarginLeft;
      const y =
        props.fixedChartHeight -
        props.fixedMarginBottom -
        xAxisTextHeightRef.current;
      const dataElement = props.data[i] as any;
      const dataKey = Object.keys(dataElement)[0];
      ctx.beginPath();
      ctx.strokeStyle = "#000000ff";
      ctx.textAlign = "center";
      ctx.textBaseline = "hanging";
      ctx.fillText(dataKey, x, y);
      if (i == 0) {
        ctx.moveTo(x, props.fixedMarginTop + 15);
      } else {
        ctx.moveTo(x, y - 15 - props.xAxisGap);
      }
      ctx.lineTo(x, y - 5 - props.xAxisGap);
      ctx.stroke();
    }
  };

  const animateLines = (
    ctx: CanvasRenderingContext2D,
    paths: Record<string, { x: number; y: number }[]>,
    startElapsed = 0
  ) => {
    const maxProgress = Object.values(paths)[0].length - 1; // Total path segments
    const duration = props.animationTimeInSeconds * 1000; // Duration in milliseconds (5 seconds)

    let currentProgress = 0;
    let startTime: number | null = null; // Track the animation's start time
    setIsAnimating(true);

    const draw = (timestamp: number) => {
      if (!startTime) startTime = timestamp; // Initialize startTime on the first frame
      const elapsed = timestamp - startTime + startElapsed; // Time elapsed since the start in milliseconds

      setCurrentElapsed(elapsed);

      // Calculate the new progress based on elapsed time
      currentProgress = (elapsed / duration) * maxProgress;

      //@ts-ignore
      resetAndDrawBackground(ctx);

      // Draw y axis
      drawYAxis(ctx);

      // Draw x axis
      drawXAxis(ctx);

      // Draw lines (and labels)
      drawLines(ctx, paths, currentProgress);

      // Draw extra objects
      drawExtraObjects(ctx);

      // Continue the animation if within the duration
      if (elapsed < duration) {
        animationFrameIdRef.current = requestAnimationFrame(draw);
      } else {
        currentProgress = maxProgress; // Ensure we end exactly at the last point
        // Optionally, reset for replay or leave as is

        setIsAnimating(false);
      }
    };

    setIsAnimating(true);
    animationFrameIdRef.current = requestAnimationFrame(draw);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{ width: props.fixedChartWidth, height: props.fixedChartHeight }}
      >
        <canvas
          style={{ border: "0px solid #666" }}
          ref={canvasRef}
          width={props.fixedChartWidth}
          height={props.fixedChartHeight}
          onClick={handleCanvasClick}
          onMouseMove={handleCanvasMouseMove}
          onMouseDown={handleCanvasMouseDown}
          onMouseUp={handleCanvasMouseUp}
        />
      </div>
      <div style={{}}>
        {!isAnimating && (
          <>
            <button
              onClick={() => {
                if (ctxRef.current && pathsRef.current && canvasRef.current)
                  animateLines(ctxRef.current, pathsRef.current, 0);
              }}
            >
              Play
            </button>

            <button
              onClick={() => {
                if (canvasRef.current && ctxRef.current && pathsRef.current) {
                  animateLines(ctxRef.current, pathsRef.current, 0);
                  handleRecordCanvas(
                    canvasRef.current,
                    props.animationTimeInSeconds * 1000
                  );
                  // Timer for showing text of record finished
                  setTimeout(function () {
                    props.onRecordFinished();
                  }, props.animationTimeInSeconds * 1000);
                }
              }}
            >
              Play and record
            </button>

            <button
              onClick={() => {
                // Add object to extra objects
                props.onAddExtraObject({
                  type: "text",
                  value: {
                    textValue: "Example text",
                    fontSize: 40,
                    fontColor: "#77777777",
                  },
                  object: null,
                  hover: false,
                  moving: false,
                  movingOffsetPosition: { x: 0, y: 0 },
                  position: { x: 200, y: 150 },
                });
                redraw();
              }}
            >
              Add text object
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CanvasLineChart;
