import React, { useState } from "react";

const ExpandableSection = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <h4
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer", userSelect: "none" }}
      >
        {title} {isOpen ? "[-]" : "[+]"}
      </h4>
      {isOpen && <div style={{ paddingLeft: "0.9em" }}>{children}</div>}
    </div>
  );
};

export default ExpandableSection;
